<script>
import HorizontalMenu from '@/components/general/HorizontalMenu'
import Breadcrumbs from '@/components/general/Breadcrumbs'
export default {
  components: {
    HorizontalMenu,
    Breadcrumbs
  },
  name: 'Users',
  data () {
    return {
      selectedMenu: 0,
      menuOptions: [
        { to: { name: 'business.admin.center.users.active' }, text: 'admin.center.users:menu.active', icon: 'mdi-account' },
        { to: { name: 'business.admin.center.users.removed' }, text: 'admin.center.users:menu.removed', icon: 'mdi-account-off' },
        { to: { name: 'business.admin.center.users.invites' }, text: 'admin.center.users:menu.invite', icon: 'mdi-email-plus-outline' }
      ]
    }
  },
  computed: {
    breadcrumbsPaths () {
      const path = [{ text: 'admin.center:menu.3:title', href: this.$route.path }]
      return path
    }
  }
}
</script>
<template>
  <section class="users--container center-small">
    <div class="default-header">
      <breadcrumbs :items="breadcrumbsPaths"/>
      <h2>{{ $t('admin.center.users:title') }}</h2>
    </div>
    <horizontal-menu :options="menuOptions" v-model="selectedMenu"></horizontal-menu>
    <div class="users--body">
      <router-view></router-view>
    </div>
  </section>
</template>
<style lang="scss">
.users--container {
  .horizontal-menu--container {
    margin-bottom: 0px;
  }
  .users--body {
    margin-top: -44px;
  }
}

@media only screen and (max-width: 940px) {
  .users--container {
  .horizontal-menu--container {
    margin-bottom: 0px;
  }
  .users--body {
    margin-top: 40px;
  }
}
}
</style>
